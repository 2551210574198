import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Profile from '../images/eden.jpg'
import Logo from "../images/logo.png"

const About = () => (
  <Layout>
    <SEO title="about" />
    <main className="c12 summary main">
        <div>
            <h3>Designer. Neuroscientist. Software Engineer. Entrepreneur.</h3>
            <br/>
            <h5>Hi! My name is Eden and I'm passionate about using human-centered design to tackle systemic inequities. I want to make our systems more inclusive, accessible, and equitable to everyone - not just the mainstream.</h5>
            <br/>
            <p>Some other fun facts: I coded this website from scratch <span>🤓</span>, and my logo (<img alt="Eden Adler logo" src={Logo} className="ea-logo-inline"/>) combines the first letters from my name in English ("E") and Hebrew ("ע") in cursive.</p>
        </div>
        <div className="profile-pic">
            <img alt="picture of Eden Adler" src={Profile} className="avi"/>
        </div>
    </main>
  </Layout>
)

export default About
